td {
	vertical-align: middle;
}

body {
	margin: 0;
	padding: 0;
	font-family: 'Source Sans Pro', Helvetica Neue,Hevetica,sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3 .h1,
.h2,
.h3 {
  margin: 0.5em 0 0.25em;
}
h1,
.h1 {
  font-size: 32px;
  font-weight: 900;
  letter-spacing: -0.02em;
  line-height: 1.2;
}
@media (min-width: 768px) {
  h1,
  .h1 {
    font-size: 40px;
  }
}
@media (min-width: 992px) {
  h1,
  .h1 {
    font-size: 48px;
  }
}
h2,
.h2 {
  font-size: 28px;
  font-weight: 900;
  letter-spacing: -0.015em;
  line-height: 1.3;
}
@media (min-width: 768px) {
  h2,
  .h2 {
    font-size: 32px;
  }
}
h3,
.h3 {
  font-size: 20px;
  font-weight: 900;
  line-height: 1.4;
}
@media (min-width: 768px) {
  h3,
  .h3 {
    font-size: 24px;
    letter-spacing: -0.01em;
  }
}
p,
h4 {
  margin: .5em 0 1em;
}

